// src/projects/Project3.js
import React from 'react';
import Layout from '../components/Layout';
import Project from '../components/Project';
import project3Image from '../assets/project3.jpg';

const Project6 = () => (
  <Layout>
    <Project
      title="Project 6"
      image={project3Image}
      description="This is the detailed description of Project 3."
      links={[
        { to: "/projects/project5", text: "Project 5" },
        { to: "/projects/project1", text: "Project 1" },
        // Add more links as needed
      ]}
    />
  </Layout>
);

export default Project6;
