// src/components/BlogPost.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import styled from 'styled-components';

const BlogContainer = styled(Container)`
  padding: 2rem;
`;

const BlogTitle = styled(Typography)`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const BlogImage = styled(CardMedia)`
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const BlogExcerpt = styled(Typography)`
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
`;

const ReadNextLink = styled(Button)`
  background-color: #ff6347;
  color: white;
  text-transform: none;
  &:hover {
    background-color: #e5533d;
  }
`;

const BlogPost = ({ title, image, excerpt, nextLink }) => (
  <BlogContainer>
    <Card>
      <CardContent>
        <BlogTitle variant="h3">{title}</BlogTitle>
        <BlogImage component="img" src={image} alt={title} />
        <BlogExcerpt variant="body1">{excerpt}</BlogExcerpt>
        <ReadNextLink component={Link} to={nextLink} variant="contained">
          Read Next
        </ReadNextLink>
      </CardContent>
    </Card>
  </BlogContainer>
);

export default BlogPost;
