// src/projects/Project1.js
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import project3Image from '../assets/project3.jpg';

const ProjectContainer = styled.div`
  padding: 2rem;
`;

const ProjectTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const ProjectImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  color: #555;
  margin-top: 1rem;
`;

const Project3 = () => (
  <Layout>
    <ProjectContainer>
      <ProjectTitle>Project 3</ProjectTitle>
      <ProjectImage src={project3Image} alt="Project 3" />
      <ProjectDescription>
        This is the detailed description of Project 1. It includes information about the project, the goals, the process, and the results.
      </ProjectDescription>
    </ProjectContainer>
  </Layout>
);

export default Project3;
