// src/pages/Blog.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import Layout from '../components/Layout';
import blog1Image from '../assets/blog1.jpg';
import blog2Image from '../assets/blog2.jpg';
import blog3Image from '../assets/blog3.jpg';


const blogPosts = [
  { id: 1, title: 'Blog Post 1', excerpt: 'This is a short excerpt or summary of Blog Post 1. It gives readers a glimpse into what the post is about.', image: blog1Image, link: '/blog/blogpost1' },
  { id: 2, title: 'Blog Post 2', excerpt: 'This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.', image: blog2Image, link: '/blog/blogpost2' },
  { id: 3, title: 'Blog Post 3', excerpt: 'This is a short excerpt or summary of Blog Post 3. It gives readers a glimpse into what the post is about.', image: blog3Image, link: '/blog/blogpost3' },
  // Add more blog posts as needed
];

const Blog = () => (
  <Layout>
    <Container sx={{ py: 5 }}>
      <Typography variant="h3" component="h1" align="center" gutterBottom>
        Blog
      </Typography>
      <Grid container spacing={4}>
        {blogPosts.map((post) => (
          <Grid item key={post.id} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Link to={post.link} style={{ textDecoration: 'none' }}>
                <CardMedia
                  component="img"
                  sx={{ height: 200 }}
                  image={post.image}
                  alt={post.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {post.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {post.excerpt}
                  </Typography>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    
  </Layout>
);

export default Blog;
