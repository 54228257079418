// src/blog/BlogPost1.js
import React from 'react';
import Layout from '../components/Layout';
import BlogPost from '../components/BlogPost'; // Adjust the path if necessary
import blog1Image from '../assets/blog1.jpg';

const BlogPost1 = () => (
  <Layout>
    <BlogPost
      title="Blog Post 1"
      image={blog1Image}
      excerpt="This is a short excerpt or summary of Blog Post 1. It gives readers a glimpse into This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about. what the post is about."
      nextLink="/blog/blogpost2"
    />
  </Layout>
);

export default BlogPost1;
