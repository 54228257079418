// src/projects/Project1.js
import React from 'react';
import Layout from '../components/Layout';
import Project from '../components/Project';
import project1Image from '../assets/project1.jpg';

const Project1 = () => (
  <Layout>
    <Project
      title="Project 1"
      image={project1Image}
      description="This is the detailed description of Project 1. It includes information about the project, the goals, the process, and the results."
      links={[
        { to: "/projects/project2", text: "Project 2" },
        { to: "/projects/project3", text: "Project 3" },
        // Add more links as needed
      ]}
    />
  </Layout>
);

export default Project1;
