// src/pages/About.js
import React from 'react';
import Layout from '../components/Layout';
import { Box, Typography, Container, Grid, Card, CardMedia, CardContent } from '@mui/material';
import ferdousImg from '../assets/ferdous.png'; // Add actual image path
import masumImg from '../assets/masum.jpg'; // Add actual image path

const About = () => (
  <Layout>
    <Container sx={{ py: 5 }}>
      <Grid container spacing={4} sx={{ display: 'flex' }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
          <Card sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
            <CardMedia
              component="img"
              sx={{ width: 150, height: 150, borderRadius: '50%', mb: 2 }}
              image={ferdousImg}
              alt="Ferdous Hossain"
            />
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                Ferdous Hossain
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                FOUNDER & WEB DESIGNER
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Ferdous Hossain is the leader of TheWebPal company. He excels in making websites,
                managing digital projects, and ensuring projects are completed on time and within
                budget. His dedication earns him respect and admiration from his teammates.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
          <Card sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
            <CardMedia
              component="img"
              sx={{ width: 150, height: 150, borderRadius: '50%', mb: 2 }}
              image={masumImg}
              alt="Shaikh Abdullah Al Masum"
            />
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                Shaikh Abdullah Al Masum
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                FOUNDER & PROJECT COORDINATOR
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Masum, founder and project coordinator at TheWebPal, is skilled in web design,
                development, and digital project management. He ensures timely and cost-effective
                project completion, earning him respect and trust from his colleagues.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>

    <Container sx={{ py: 5 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Our Mission
        </Typography>
        <Box component="ul" sx={{ pl: 2 }}>
          {[
            { title: 'Innovation', description: 'We constantly seek new and creative solutions to every problem.' },
            { title: 'Collaboration', description: 'We work closely with our clients and each other, fostering strong relationships and innovative ideas.' },
            { title: 'Client Success', description: 'We measure our success not only by our achievements but also by the satisfaction and ROI of our clients.' },
          ].map((item) => (
            <Box component="li" key={item.title} sx={{ mb: 2 }}>
              <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                {item.title}:
              </Typography>{' '}
              <Typography variant="body1" component="span">
                {item.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>

    <Container sx={{ py: 5 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Our Vision
      </Typography>
      <Typography variant="body1">
        These core values set us apart from the competition and guide us in providing innovative
        strategies and personalized solutions for every client project. At TheWebPal, we believe
        that through innovation, collaboration, and client success, we can build better businesses
        and a better tomorrow.
      </Typography>
    </Container>
  </Layout>
);

export default About;
