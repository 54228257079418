// src/projects/Project2.js
import React from 'react';
import Layout from '../components/Layout';
import Project from '../components/Project';
import project2Image from '../assets/project2.jpg';

const Project2 = () => (
  <Layout>
    <Project
      title="Project 2"
      image={project2Image}
      description="This is the detailed description of Project 2."
      links={[
        { to: "/projects/project1", text: "Project 1" },
        { to: "/projects/project3", text: "Project 3" },
        // Add more links as needed
      ]}
    />
  </Layout>
);

export default Project2;
