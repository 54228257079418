// src/pages/Services.js
import React from 'react';
import Layout from '../components/Layout';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material';
import bgImage1 from '../assets/bg-image1.jpg';
import bgImage2 from '../assets/bg-image2.jpg';
import bgImage3 from '../assets/bg-image3.jpg';
import landingPageImage from '../assets/landing-page.jpg';
import webDesignImage from '../assets/web-design.jpg';
import cmsDevImage from '../assets/cms-development.jpg';
import infoArchitectureImage from '../assets/info-architecture.jpg';
import webRedesignImage from '../assets/web-redesign.jpg';
import seoOptimizationImage from '../assets/seo-optimization.jpg';
import Blog from './Blog';

const Services = () => (
  <Layout>
    <Box
      sx={{
        width: '100%',
        height: '60vh',
        backgroundImage: `url(${bgImage1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Typography variant="h1" component="h1" sx={{ fontSize: '2.5rem', mb: 1 }}>
        Website Design & Development Services
      </Typography>
      <Typography variant="h2" component="h2" sx={{ fontSize: '1.5rem', mb: 1 }}>
        Your website should convert, not confuse your visitors. A perfect blend of good user experience and aesthetic design can ignite growth - we will help you get there.
      </Typography>
      <Typography variant="h2" component="h2" sx={{ fontSize: '1.5rem' }}>
        Transform your website!
      </Typography>
    </Box>

    <Container sx={{ py: 5 }}>
      <Grid container spacing={4} sx={{ display: 'flex', alignItems: 'center', background: '#f9fafb', py: 5 }}>
        <Grid item xs={12} md={6}>
          <Box component="img" src={bgImage2} alt="Optimize reach and maximize impact" sx={{ width: '100%', borderRadius: 2 }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" component="h1" sx={{ fontSize: '2.5rem', mb: 2 }}>
            Optimize reach and maximize impact. Go responsive!
          </Typography>
          <Typography variant="h2" component="h2" sx={{ fontSize: '1.5rem', mb: 2 }}>
            You need a website that adapts to any screen size. No loading delays, no navigation issues, and no performance problems. Just results. That’s what we do.
          </Typography>
          <Typography variant="h2" component="h2" sx={{ fontSize: '1.5rem' }}>
            FREE web design consultation!
          </Typography>
        </Grid>
      </Grid>
    </Container>

    <Container sx={{ py: 5 }}>
      <Grid container spacing={4} sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" component="h1" sx={{ fontSize: '2.5rem', mb: 2, textAlign: { xs: 'center', md: 'right' } }}>
            Stunning visuals, smooth experience - we craft your success!
          </Typography>
          <Typography variant="h2" component="h2" sx={{ fontSize: '1.5rem', mb: 2, textAlign: { xs: 'center', md: 'right' } }}>
            Boost your online presence with an intuitive website that helps you connect to your audience. Turn browsers into revenue streams.
          </Typography>
          <Typography variant="h2" component="h2" sx={{ fontSize: '1.5rem', textAlign: { xs: 'center', md: 'right' } }}>
            Craft your website!
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box component="img" src={bgImage3} alt="Stunning visuals, smooth experience" sx={{ width: '100%', borderRadius: 2 }} />
        </Grid>
      </Grid>
    </Container>

    <Container sx={{ py: 5, background: '#f3f4f6' }}>
      <Typography variant="h2" component="h2" sx={{ textAlign: 'center', fontSize: '2rem', mb: 3 }}>
        Our comprehensive web design services
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {[{
          img: landingPageImage,
          title: 'Landing Page Design',
          desc: 'To ensure intuitive layouts and a seamless user journey for your website, we craft landing pages with clear highlights.'
        }, {
          img: webDesignImage,
          title: 'Website Design',
          desc: 'From visual components to responsive design, we do everything necessary to craft your desired website.'
        }, {
          img: cmsDevImage,
          title: 'CMS Development',
          desc: 'Empower your content and streamline your workflow. We build custom CMS that puts you in control, from seamless editing to effortless publishing.'
        }, {
          img: infoArchitectureImage,
          title: 'Information Architecture',
          desc: 'You need a website that is sleek and accessible to everyone. Our niche-specific solution helps you make it happen.'
        }, {
          img: webRedesignImage,
          title: 'Website Redesign',
          desc: 'To enhance your user engagement and brand image, we redesign your website with maximum efficiency.'
        }, {
          img: seoOptimizationImage,
          title: 'SEO Optimization',
          desc: 'Increase your website’s search engine visibility with our technical and off-page optimization solution.'
        }].map(service => (
          <Grid item xs={12} md={5} key={service.title}>
            <Card sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <CardMedia
                component="img"
                image={service.img}
                alt={service.title}
                sx={{ width: 80, height: 80, borderRadius: 2, m: 1 }}
              />
              <CardContent>
                <Typography variant="h3" component="h3" sx={{ fontSize: '1.25rem', mb: 1 }}>
                  {service.title}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {service.desc}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    <Blog />
  </Layout>
);

export default Services;
