// src/blog/BlogPost3.js
import React from 'react';
import Layout from '../components/Layout';
import BlogPost from '../components/BlogPost'; // Adjust the path if necessary
import blog3Image from '../assets/blog3.jpg';

const BlogPost3 = () => (
  <Layout>
    <BlogPost
      title="Blog Post 3"
      image={blog3Image}
      excerpt="This is a short excerpt or summary of Blog Post 3. It gives readers a glimpse intoThis is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about. what the post is about."
      nextLink="/blog/blogpost1" // Example link, adjust as needed
    />
  </Layout>
);

export default BlogPost3;
