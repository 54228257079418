// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
//import GlobalStyles from './styles/GlobalStyles';
import Project1 from './projects/Project1';
import Project2 from './projects/Project2';
import Project3 from './projects/Project3';
import project4 from './projects/Project4';
import Project5 from './projects/Project5';
import Project6 from './projects/Project6';
import Project from './components/Project';
import BlogPost1 from './blog/BlogPost1';
import BlogPost2 from './blog/BlogPost2';
import BlogPost3 from './blog/BlogPost3';

import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';


import Footer from './components/Footer';
import BlogPost from './components/BlogPost';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signup" element={<SignUp />} /> 
        <Route path="/signin" element={<SignIn />} />
        <Route path='/components/project' element={<Project />} />
        <Route path="/projects/project1" element={<Project1 />} />
        <Route path="/projects/project2" element={<Project2 />} />
        <Route path="/projects/project3" element={<Project3 />} />
        <Route path="/projects/project4" element={<project4 />} />
        <Route path="/projects/project5" element={<Project5 />} />
        <Route path="/projects/project6" element={<Project6 />} />
        <Route path="/blog/blogpost1" element={<BlogPost1 />} />
        <Route path="/blog/blogpost2" element={<BlogPost2 />} />
        <Route path="/blog/blogpost3" element={<BlogPost3 />} />
        
        <Route path="/components/blogpost" element={<BlogPost />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;