// src/pages/Contact.js
import React, { useState } from 'react';
import Layout from '../components/Layout';
import { Container, Grid, Card, CardContent, Typography, Divider, TextField, Button } from '@mui/material';
import styled from 'styled-components';

const ContactContainer = styled(Container)`
  padding: 2rem;
`;

const ContactInfoCard = styled(Card)`
  background: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const ContactHeading = styled(Typography)`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const Heading = styled(Typography)`
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SubmitButton = styled(Button)`
  background: #ff6347;
  color: #fff;
  padding: 1rem 2rem;
  font-size: 1rem;
  &:hover {
    background: #e5533d;
  }
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name.trim() === '' || formData.email.trim() === '' || formData.message.trim() === '') {
      setFormError(true);
    } else {
      setSubmitted(true);
      setFormError(false);
      // Implement your form submission logic here
      console.log(formData);
    }
  };

  return (
    <Layout>
      <ContactContainer>
        <ContactHeading variant="h3" component="h1" align="center" gutterBottom>Contact Us</ContactHeading>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <ContactInfoCard>
              <CardContent>
                <Typography variant="h5" component="h3">Our Address</Typography>
                <Divider />
                <Typography variant="body1">9 Emerson Park Court, RM11 1UX, United Kingdom</Typography>
              </CardContent>
            </ContactInfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <ContactInfoCard>
              <CardContent>
                <Typography variant="h5" component="h3">Email</Typography>
                <Divider />
                <Typography variant="body1">info@thewebpal.com</Typography>
              </CardContent>
            </ContactInfoCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <ContactInfoCard>
              <CardContent>
                <Typography variant="h5" component="h3">Phone</Typography>
                <Divider />
                <Typography variant="body1">+44 7502 280328</Typography>
              </CardContent>
            </ContactInfoCard>
          </Grid>
        </Grid>
      </ContactContainer>

      <Heading variant="h4" component="h2">Send Us a Message</Heading>

      {submitted ? (
        <Typography variant="body1">Thank you for your message. We will get back to you soon!</Typography>
      ) : (
        <ContactForm onSubmit={handleSubmit}>
          {formError && <Typography variant="body1" color="error">Please fill in all fields.</Typography>}
          <TextField
            type="text"
            name="name"
            label="Your Name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <TextField
            type="email"
            name="email"
            label="Your Email"
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <TextField
            name="message"
            label="Your Message"
            variant="outlined"
            multiline
            rows={5}
            value={formData.message}
            onChange={handleChange}
            required
          />
          <SubmitButton variant="contained" type="submit">Send Message</SubmitButton>
        </ContactForm>
      )}
    </Layout>
  );
};

export default Contact;
