// src/pages/Portfolio.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, Container } from '@mui/material';
import Layout from '../components/Layout';
import project1Image from '../assets/project1.jpg';
import project2Image from '../assets/project2.jpg';
import project3Image from '../assets/project3.jpg';
import Blog from './Blog';

const projects = [
  { id: 1, title: 'Project 1', image: project1Image, link: '/projects/project1' },
  { id: 2, title: 'Project 2', image: project2Image, link: '/projects/project2' },
  { id: 3, title: 'Project 3', image: project3Image, link: '/projects/project3' },
  { id: 4, title: 'Project 4', image: project1Image, link: '/projects/project4' },
  { id: 5, title: 'Project 5', image: project2Image, link: '/projects/project5' },
  { id: 6, title: 'Project 6', image: project3Image, link: '/projects/project6' },
];

const Portfolio = () => (
  <Layout>
    <Container sx={{ py: 5 }}>
      <Typography variant="h3" component="h1" align="center" gutterBottom>
        Our Portfolio
      </Typography>
      <Grid container spacing={4}>
        {projects.map((project) => (
          <Grid item key={project.id} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Link to={project.link} style={{ textDecoration: 'none' }}>
                <CardMedia
                  component="img"
                  sx={{ height: 300 }}
                  image={project.image}
                  alt={project.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {project.title}
                  </Typography>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    <Blog />
  </Layout>
);

export default Portfolio;
