// src/components/Project.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ProjectContainer = styled.div`
  padding: 2rem;
`;

const ProjectTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const ProjectImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  color: #555;
  margin-top: 1rem;
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
`;

const ProjectLink = styled(Link)`
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  color: #333;
  transition: background 0.3s ease;

  &:hover {
    background: #eaeaea;
  }
`;

const Project = ({ title, image, description, links }) => (
  <ProjectContainer>
    <ProjectTitle>{title}</ProjectTitle>
    <ProjectImage src={image} alt={title} />
    <ProjectDescription>{description}</ProjectDescription>
    
    <h3>Explore Other Projects:</h3>
    <ProjectsGrid>
      {links.map(link => (
        <ProjectLink key={link.to} to={link.to}>
          {link.text}
        </ProjectLink>
      ))}
    </ProjectsGrid>
  </ProjectContainer>
);

export default Project;
