// src/blog/BlogPost2.js
import React from 'react';
import Layout from '../components/Layout';
import BlogPost from '../components/BlogPost';
import blog2Image from '../assets/blog2.jpg';

const BlogPost2 = () => (
  <Layout>
    <BlogPost
      title="Blog Post 2"
      image={blog2Image}
      excerpt="This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about.This is a short excerpt or summary of Blog Post 2. It gives readers a glimpse into what the post is about."
      nextLink="/blog/blogpost3"
    />
  </Layout>
);

export default BlogPost2;
