// src/pages/Home.js
import React from 'react';
import Layout from '../components/Layout';
import { Box, Typography, Grid, Card, CardMedia, CardContent, Container,  } from '@mui/material';
import project1 from '../assets/project1.jpg'; // Correct path to project1 image
import project2 from '../assets/project2.jpg'; // Correct path to project2 image
import project3 from '../assets/project3.jpg'; // Correct path to project3 image
//import Button from '@mui/material/Button';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Highlights from '../components/Highlights';
import Pricing from '../components/Pricing';

const Home = () => (
  <Layout>
    <Hero />
    <Features />
    <Highlights />
    

    <Container sx={{ py: 5 }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#fff', textAlign: 'center', p: 2, backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 2 }}>
        Our Portfolio
      </Typography>
      <Grid container spacing={4}>
        {[
          { image: project1, title: 'Project 1', description: 'A stunning web design project for a local business.' },
          { image: project2, title: 'Project 2', description: 'An SEO optimization project that boosted client\'s search engine ranking.' },
          { image: project3, title: 'Project 3', description: 'A content marketing campaign that increased engagement significantly.' },
        ].map((project) => (
          <Grid item xs={12} md={4} key={project.title}>
            <Card sx={{ height: '100%' }}>
              <CardMedia
                component="img"
                height="330"
                image={project.image}
                alt={project.title}
              />
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom >
                  {project.title}
                </Typography>
                <Typography variant="body1">
                  {project.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    <Pricing />
  </Layout>
);

export default Home;
